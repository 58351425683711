import React from 'react';
import { Grid, Typography } from '@mui/material';
import LogoText from '../components/LogoText';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';

const OrganisersTab = () => {
  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );

  const organizers = eventFullDetailsData?.data?.organizers;

  return (
    <Grid container>
      {organizers?.map((organizer, index) => (
        <React.Fragment key={index}>
          <Grid item>
            <LogoText
              label={organizer?.title}
              size={1.625}
              weight="bold"
              colors={['black']}
            />
          </Grid>
          <Grid item mt={1.5} mb={3.5} className='organizations-para'>
            <RenderedContent>
              <Typography fontSize={'0.875rem'} lineHeight={2}>
                {organizer?.descriptions}
              </Typography>
            </RenderedContent>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default OrganisersTab;
