import React from 'react';
import { Box, Grid, Icon, Typography } from '@mui/material';
import RenderedContent from './RenderedContent';

interface VenueScheduleItemProps {
  title: string;
  schedule: string;
  icon?: string;
}

const VenueScheduleItem: React.FC<VenueScheduleItemProps> = ({
  title,
  schedule,
  icon,
}) => {
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      my={1.5}
      flexWrap={'wrap'}
    >
      <Box
        bgcolor="gray"
        borderRadius={4}
        width={62}
        height={62}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Icon style={{ fontSize: 28, color: 'white' }}>{icon}</Icon>
      </Box>

      <Box flexBasis={'calc(100% - 85px)'} display={'flex'} alignItems={'center'}>
        <Box color="gray">
          <RenderedContent>
            <Typography
              fontSize={14}
              fontWeight={'bold'}
              fontFamily={'Red Hat Display'}
            >
              {schedule}
            </Typography>
          </RenderedContent>
          <RenderedContent>
            <Typography
              fontSize={14}
              fontWeight={'bold'}
              fontFamily={'Red Hat Display'}
            >
              {title}
            </Typography>
          </RenderedContent>
        </Box>
      </Box>
    </Grid>
  );
};

export default VenueScheduleItem;
