import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ProfileCard from '../components/ProfileCard';
import ButtonTabPanel from '../components/ButtonTabPanel';
import theme from '../theme/basic';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { IFaculty } from '../api/interfaces/EventFullDetailsInterface';

/**
 * here we can't use "TabSelector" because of
 * it's specify for buttons. We can improve it later
 */

const FacultyTab = () => {
  const { eventFullDetailsData, eventFullDetailsLoading } = useSelector(
    (state: RootState) => state.api
  );

  const { faculty } = useSelector((state: RootState) => state.tabLocation);

  const locations = eventFullDetailsData?.data?.locations;

  const faculties = (locations &&
    locations[faculty] &&
    locations[faculty]?.faculties) as IFaculty[];

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  // const smallDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const handleTabClick = (index: number) => {
    setTabIndex(index);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={7} paddingRight={'10px'} className='faculty-left-dsk'>
        <Box className="faculty-sticky-left">
        {faculties?.map(({ name, picture, qualifications, about }, index) => (
          <React.Fragment key={index}>
            <Button
              className='faculty-tb-btns'
              style={{
                width: '100%',
                flexGrow: 1,
                backgroundColor: tabIndex === index ? '#FBF8E5' : 'white',
                borderLeft:
                  tabIndex === index
                    ? `4px solid ${theme.palette.primary.main}`
                    : '',
              }}
              // variant={tabIndex === index ? 'contained' : 'outlined'}
              onClick={() => handleTabClick(index)}
            >
              <ProfileCard
                name={name}
                picture={picture}
                qualifications={qualifications}
                about={about}
              />
            </Button>
          </React.Fragment>
        ))}
        </Box>
      </Grid>

      {/* {!smallDesktop && ( */}
        <Grid item xs={6} md={6} className='faculty-righ-dsk'>
          <Box className="">
            {faculties?.map(({ name, picture, qualifications, about }, index) => (
              <ButtonTabPanel key={index} value={tabIndex} index={index}>
                <ProfileCard
                  full={true}
                  name={name}
                  picture={picture}
                  qualifications={qualifications}
                  about={about}
                  scale={1.7}
                />
              </ButtonTabPanel>
            ))}
          </Box>
        </Grid>
      {/* )} */}

      {/* {smallDesktop && ( */}
        <Dialog onClose={handleClose} open={openDialog}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid item xs={12} className='faulculty-mobile-modals'>
            {faculties?.map(
              ({ name, picture, qualifications, about }, index) => (
                <ButtonTabPanel key={index} value={tabIndex} index={index}>
                  <ProfileCard
                    full={true}
                    name={name}
                    picture={picture}
                    qualifications={qualifications}
                    about={about}
                    scale={1.2}
                  />
                </ButtonTabPanel>
              )
            )}
          </Grid>
        </Dialog>
      {/* )} */}
    </Grid>
  );
};

export default FacultyTab;
