import React, {useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EventTicketSection from '../sections/EventTicketSection';
import EventTabSection from '../sections/EventTabSection';
import { fetchEventFullDetailsData } from '../redux/apiSlice';
import { RootState } from '../redux/store';
import InvalidRoute from '../components/InvalidRoute';
import {
  Box,
  Skeleton,
  ThemeProvider,
  createTheme,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText, Dialog
} from '@mui/material';
import LinearIndeterminate from '../components/LinearIndeterminate';
import EventListSection from '../sections/EventListSection';
import { EventType } from '../api/interfaces/CommonInterfaces';
import theme from '../theme/basic';
import CloseIcon from "@mui/icons-material/Close";
import RenderedContent from "../components/RenderedContent";

const EventPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventID = searchParams.get('id') as string;
  const [showMessageTab, setShowMessageTab] = useState<boolean>(false);

  const { eventFullDetailsData, eventFullDetailsLoading, error } = useSelector(
    (state: RootState) => state.api
  );

  const primaryColor = eventFullDetailsData?.data?.heading_color as string;
  const secondaryColor = eventFullDetailsData?.data
    ?.heading_reg_button_color as string;

  const dynamicTheme = React.useMemo(() => {
    return createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          ...theme.palette.primary,
          main: primaryColor || theme.palette.primary.main,
        },
        secondary: {
          ...theme.palette.secondary,
          main: secondaryColor || theme.palette.secondary.main,
        },
      },
    });
  }, [primaryColor, secondaryColor]);

  React.useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchEventFullDetailsData(eventID));
    };

    fetchData();
  }, [eventID]);

  React.useEffect(() => {
    if(eventFullDetailsData && !eventFullDetailsData?.data?.registration_link) {
      setShowMessageTab(true);
    }
  }, [eventFullDetailsData]);

  const relatedEvents = eventFullDetailsData?.data?.related_events;

  if (eventFullDetailsLoading) {
    return <LinearIndeterminate />;
  }

  const tabMessage = eventFullDetailsData?.data?.popup_message_for_event_page as string;
  const handleCloseDialog = () => setShowMessageTab(false);

  return (
    <ThemeProvider theme={dynamicTheme}>
      {/*  @TODO expand with error type accr error message */}
      {error ? (
        <InvalidRoute
          title={'Event not found!'}
          description={"The Event you're looking for does not found."}
        />
      ) : (
        <>
          <EventTicketSection />
          <EventTabSection />
          <Box marginTop={'-1.75rem'}>
            <EventListSection
              title={'Recent Events'}
              themeColor={'#FDAF31'}
              events={relatedEvents?.map((event) => new EventType(event))}
              eventsLoading={eventFullDetailsLoading}
              route={'/events'}
            />
          </Box>
          {/* Dialog Box */}
          <Dialog open={showMessageTab && !!tabMessage} onClose={handleCloseDialog}>
            <DialogTitle sx={{ paddingTop: '0.5rem' }}>
              <IconButton
                  aria-label="close"
                  className='popup-close-btn'
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 0,
                    width: '5px',
                    color: (theme) => theme.palette.grey[500],
                  }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '12px', paddingBottom: '4px' }}>
              <DialogContentText m={0} className='popup-text'>
                <RenderedContent>{tabMessage}</RenderedContent>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </ThemeProvider>
  );
};

export default EventPage;
