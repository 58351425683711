import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import Image from 'mui-image';
import RenderedContent from './RenderedContent';
import theme from '../theme/basic';

interface ProfileCardProps {
  name: string;
  picture: string;
  qualifications: string;
  about: string;
  full?: boolean;
  scale?: number;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  full = false,
  name,
  picture,
  qualifications,
  about,
  scale = 1,
}) => {
  return (
    <>
      <Grid
        container
        p="1rem"
        textAlign={'left'}
        color={'black'}
        textTransform={'none'}
        className='faculty-left-btns'
      >
        <Grid item sm={12} md={full ? 3.0 : 2.0} lg={full ? 3.5 : 2.5} className='faculty-profiles'>
          <Image
            style={{ borderRadius: '50%' }}
            duration={600}
            src={picture}
            width={90 * scale}
            height={90 * scale}
            fit="cover"
          />
        </Grid>
        <Grid
          className="faculty-profiles-ctns"
          item
          sm={12}
          md={full ? 9 : 10}
          lg={full ? 8.5 : 9.5}
          sx={{ paddingRight: full ? '10px' : '' }                    
        }          
        >
          <RenderedContent>
            <Typography fontWeight={'bold'} fontSize={`${1.1667 * scale}rem`}>
              {name}
            </Typography>
          </RenderedContent>
          <RenderedContent>
            <Typography color={'gray'} fontSize={'0.9167rem'}>
              {qualifications}
            </Typography>
          </RenderedContent>
        </Grid>
      </Grid>
      {full && (
        <Grid container p={'1rem'} direction={'column'} className='faculty-tab-right-content'>
          <Divider sx={{ color: 'gray', marginBottom: '2.25rem' }} />
          <RenderedContent>
            <Typography>{about}</Typography>
          </RenderedContent>
        </Grid>
      )}
    </>
  );
};

export default ProfileCard;
